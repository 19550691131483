html, body {
  font-family: 'Inter', sans-serif;
  height: 1010px; 
  overflow: hidden;
}

.my-section {
  font-family: 'Inter', sans-serif;
  overflow-y: auto; /* Включаем вертикальную прокрутку */
  margin-top: 2px;
  margin-bottom: 1px;
  margin-right: 10px;
  padding-top: 10px;
}
  .my-section::-webkit-scrollbar {
    width: 10px;
  }
  .my-section::-webkit-scrollbar-track{
    background-color: rgb(187, 186, 186);
    border-radius: 6px;
    margin-top: 125px;
  }
  .my-section::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 6px;
  }
  .div-list-containerMonitor-first {
    font-family: 'Inter', sans-serif;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 95%, rgba(167, 166, 166, 0.5) 100%);
    width: 75%;
    height: 49%;
    background-color: white;
    border-radius: 15px;
    border: 6px solid rgb(202, 202, 202);
    margin-bottom: 15px;
  }
  .div-list-containerMonitor-second {
    font-family: 'Inter', sans-serif;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 95%, rgba(167, 166, 166, 0.5) 100%);
    width: 75%;
    height: 95%;
    padding: 15px;
    background-color: white;
    border-radius: 15px;
    border: 6px solid rgb(202, 202, 202);
    overflow-x: auto,
  }
  .div-grafics-first{
    font-family: 'Inter', sans-serif;
    width: 23%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 15px 15px 0 15px; 
    border: 6px solid rgb(202, 202, 202);
    border-left: 8px; 
    position: relative;
  }
  .div-Grafics {
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    height: 914px;
    background-color: rgb(202, 202, 202);
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 1px;
    padding-right: 1px;
  }
  .div-back-to-map {
  font-family: 'Inter', sans-serif; 
  width: 75%;
  height: 5%;
  background-color: white;
  text-align: center;
  border: 4px solid rgb(202, 202, 202);
  border-radius: 15px;
                  }
  .div-main-map{
      font-family: 'Inter', sans-serif;
      width: 74.5%;
      height: 49.0%;
      background-color: white;
      border-radius: 15px;
      border: 6px solid white;
  }
  .div-upper-map {
    font-family: 'Inter', sans-serif;
    border-radius: 15px 15px 0 0;
    position: relative;
    width: 101%; 
    top: -0.5%;
    left: -0.49%;
    background-color: white;
    height: 5.5%;
    border-bottom: 5px solid rgb(202, 202, 202);
    display: flex;
    justify-content:center;
    align-items: center;
  }
  .div-left-to-grafics{
    font-family: 'Inter', sans-serif;
    position: absolute;
    top: 50%;
    right: -2.2%;
    transform: translateY(-50%);
    width: 9%;
    border-left: 5px solid rgb(202, 202, 202);
    height: 100%;
    margin: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 15px 15px 0;
    text-align: center;
    font-size: 20px;
  }