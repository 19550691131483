.video-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  min-height: 100vh;
}

.video-container-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  /* Другие стили по вашему усмотрению */
}


.camera-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 столбца */
  gap: 16px; /* Расстояние между элементами (по вашему выбору) */
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  margin-bottom: 30px; 
}

.pagination button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0056b3;
}

/* You can adjust the styles as needed */
