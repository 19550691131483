/* table tr td:not(:first-child),
table tr th:not(:first-child) {
  transform: translateX(-50px);
} */

.my-down-table th:nth-child(2),
.my-down-table td:nth-child(2) {
  transform: translateX(-290px);
}

.my-down-table th:nth-child(3),
.my-down-table td:nth-child(3) {
  transform: translateX(-275px);
}

.my-down-table th:nth-child(4),
.my-down-table td:nth-child(4) {
  transform: translateX(-260px);
}

.my-down-table th:nth-child(5),
.my-down-table td:nth-child(5) {
  transform: translateX(-202px);
}

.my-down-table th:nth-child(6),
.my-down-table td:nth-child(6) {
  transform: translateX(-125px);
}

.my-down-table th:nth-child(7),
.my-down-table td:nth-child(7) {
  transform: translateX(-111px);
}

.my-down-table th:nth-child(8),
.my-down-table td:nth-child(8) {
  transform: translateX(-50px);
}