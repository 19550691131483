
.second-app-zero{
        font-family: 'Inter', sans-serif;
        width: 92.8%;
        height: 49%;
        left: -15px;
        background-color: white;
        border-radius: 15px;
        border: 6px solid rgb(202, 202, 202);
        overflow-x: auto;
        position: relative;
        margin-bottom: 15px;
}
.second-app-first{
        font-family: 'Inter', sans-serif;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        height: 914px;
        background-color: rgb(202, 202, 202);
        padding-top: 11px;
        padding-bottom: 11px;
        padding-left: 1px;
        padding-right: 1px;
}
.second-app-second{
        font-family: 'Inter', sans-serif;
        position: relative;
        top: 49.9%; 
        left: 0.5%; 
        transform: translateY(-50%);
        width: 2.0%; 
        height: 99%; 
        margin: auto; 
        background-color: white;
        display: flex; 
        align-items: center;
        justify-content: center; 
        border-radius: 15px 0 0 15px; 
        text-align: center; 
        font-size: 20px;
}
.second-app-third{
        font-family: 'Inter', sans-serif;
        position: relative;
        width: 92.5%;
        height: 49.0%;
        left: -13px;
        background-color: white;
        border-radius: 15px;
        border: 6px solid white;
}
.second-app-four{
        font-family: 'Inter', sans-serif;
        border-radius: 15px 15px 0 0; 
        position: relative; 
        width: 100.55%;
        top: -0.5%;
        left: -0.3%;
        background-color: white;
        height: 6.5%;
        border-bottom: 5px solid rgb(202, 202, 202);
        display: flex;
        justify-content: center;
        align-items: center;
}
.second-app-fifth{
        font-family: 'Inter', sans-serif;
        width: 95%;
        height: 95%;
        padding: 15px;
        background-color: white;
        border-radius: 15px;
        border: 6px solid rgb(202, 202, 202);
        overflow-x:  auto;
}
.second-app-six{
        font-family: 'Inter', sans-serif;
        width: 95%;
        height: 5%;
        background-color:white;
        text-align: center;
        border: 4px solid rgb(202, 202, 202);
        border-radius: 15px;
}